// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

:root {
    --bs-primary-rgb: 194, 79, 53;
    --ofp-primary: rgb(255, 104, 68);
}

/** Skeleton **/
@keyframes shine {
    to { background-position-x: -200%; }
}
.skeleton h2:not(.skeleton-exclude),
.skeleton h4:not(.skeleton-exclude),
.skeleton medium:not(.skeleton-exclude),
.skeleton p:not(.skeleton-exclude) {
    content: ' ';
    min-width: 40%;
    display: block;
    padding: 12px 100px;
    border-radius: 5px;

    background: #eee;
    background: linear-gradient(110deg, #bcbcbc 8%, $secondary 18%, #bcbcbc 33%);
    background-size: 200% 100%;

    -webkit-animation: shine 1s infinite linear;
            animation: shine 1s infinite linear;
}

#graficoMetriche {
    &.skeleton h2:not(.skeleton-exclude),
    &.skeleton h4:not(.skeleton-exclude),
    &.skeleton medium:not(.skeleton-exclude),
    &.skeleton p:not(.skeleton-exclude) {
        padding: 8px;
    }
}

.closest-payout-date {
    .skeleton h2:not(.skeleton-exclude),
    .skeleton h4:not(.skeleton-exclude),
    .skeleton medium:not(.skeleton-exclude),
    .skeleton p:not(.skeleton-exclude) {
        max-width: 5%;
    }
}

/** Font Awesome Hacks **/
.fa-5xl {
    font-size: 5rem;
}

.text-ofp {
    color: rgb(255, 104, 68);
}
.btn-ofp {
    background-color: rgb(255, 104, 68) !important;
    border-color: rgb(255, 104, 68) !important;

    color: rgb(255, 255, 255) !important;

    font-family: 'Heebo';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;

    padding: 12px 16px 12px 16px !important;

    border-radius: 8px !important;
    border-width: 0px !important;

    &:hover {
        background-color: #c24f35 !important;
    }
}

.btn-outline-ofp {
    border-color: rgb(255, 104, 68) !important;

    color: rgb(255, 104, 68) !important;

    font-family: 'Heebo';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;

    padding: 12px 16px 12px 16px !important;

    border-radius: 8px !important;
}

@media (max-width: 768px) {
    #main-wrapper, .container-fluid {
        overflow: initial !important;
    }
}

.text-left {
    text-align: left !important;
}

.modal-content{
    background-color: #0d0a1f!important;
}

